.main_profile_layout{
    overflow: hidden;
}
.profile {
    width: 100vw;
    height: 100vh;
    background-color: #f0f2f5;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .loginWrapper_profile {
    width: 40%;
    height: 70%;
    display: flex;
    gap:"20px";
    /* background: #fff; */
    /* border-radius: 16px; */

  }
  /* .loginWrapper {
    width: 50%;
    height: 70%;
    display: flex;
    gap:"20px";
    
  } */
  .registerBox{
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .registerBox_profile{
    height: 670px !important;
  }
  .profile{
      display: flex;
  }
  
  .profileRight{
      flex: 9;
  }
  
  .profileCover{
      height: 320px;
      position: relative;
  }
  
  .profileCoverImg{
      width: 100%;
      height: 250px;
      object-fit: cover;
  
  }
  
  .profileUserImg{
      width: 125px;
      height: 125px;
      border-radius: 50%;
      object-fit: cover;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: 100px;
      border: 3px solid white;
      cursor: pointer;
   
  }
  
  .profileInfo{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }
  
  .profileInfoName{
      font-size: 24px;
  }
  
  .profileInfoDesc{
      font-weight: 300;
  }
  
  .profileRightBottom{
      display: flex;
  }
  
  #input[type="file"] {
      display: none;
  }
  .label{
    width: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }
  .image-upload{
    margin: auto;
    width: 200px;
    height: 30px;
    color: white;
    border-radius: 10px;
    background-color: black;
    text-align: center;
    cursor: pointer;
  }