.forgotBox{
    height: 250px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.loginWrapper12 {
    width: 40%;
    height: 70%;
    display: flex;
    gap:"20px"
  }