.login {
  width: 100vw;
  height: 100vh;
  background-color: #f0f2f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginWrapper {
  width: 50%;
  height: 70%;
  display: flex;
  gap:"20px";
  
}

.loginLeft,
.loginRight {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem;
}

.loginLogo {
  font-size: 50px;
  font-weight: 800;
  font-weight: bold;
  color: #1775ee;
  margin-bottom: 10px;
}

.loginDesc {
  font-size: 24px;
}

.loginBox{
    height: 300px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.loginInput{
    height: 50px;
    border-radius: 10px;
    border: 1px solid gray;
    font-size: 18px;
    padding-left: 20px;
}

.loginInput:focus{
    outline: none;
}

.loginButton{
    height: 50px;
    border-radius: 10px;
    border: none;
    background-color: #294086;
    color: white;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

.loginButton:focus{
  outline: none;
}

.loginButton:disabled{
  cursor: not-allowed;
}

.loginForgot{
    text-align: right;
    color: #1775ee;
    float:right;
}

.loginRegisterButton{
    width: 60%;
    align-self: center;
    height: 50px;
    border-radius: 10px;
    border: none;
    background-color: #42b72a;
    color: white;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

