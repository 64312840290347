
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
h1,h2{
  font-family: 'Poppins', sans-serif;
}
h1{
  font-size: 2rem;

}
h2{
  font-size: 1.5rem;
  
}
p{
  color: #696969;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
}

@media screen and (max-width:800px){
  h1{
    font-size: 1.4rem;
  
  }
  h2{
    font-size: 1.1rem;
    
  }
  p{
    color: #696969;
    font-size: .9rem;
    font-family: 'Poppins', sans-serif;
  }
}