@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
h1,h2{
  font-family: 'Poppins', sans-serif;
}
h1{
  font-size: 2rem;

}
h2{
  font-size: 1.5rem;
  
}
p{
  color: #696969;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
}

@media screen and (max-width:800px){
  h1{
    font-size: 1.4rem;
  
  }
  h2{
    font-size: 1.1rem;
    
  }
  p{
    color: #696969;
    font-size: .9rem;
    font-family: 'Poppins', sans-serif;
  }
}
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.navbar{
  /* background-color:#e6e7f7; */
  background-color: #38414a;
  display: flex;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
  /* color:blue; */
  color: #fff;
}
.navbar_main_bg_color{
  background-color: #38414a !important;
}
.bg-dark {
  background-color: #38414a !important;
}
.topbarLeft {
  flex: 3 1 !important;
 
}

.menu-icon{
  height: 48px;
  width: 48px;
  float: right;
  margin-right: 10px;
}

.add-activity{
  float:left;
  margin-left: 10px;
}

.topbarImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  margin-right:20px;
}

.home-page{
  background-color: #C8C8C8;
}

.bottomBarButtons{
  float:right;
  margin-right: 5px;
}

.navbar-links{
  margin:20px 20px 20px 20px;
}

.mar-left-100{
  margin-left:100px;
}

.navbarAddBtn{
  margin-left:10px;
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  .close{
   border-radius: 4px;
    color: #fff;
    border: 1px solid #cd485c;
    background: #cd485c;
    line-height: 30px;
    /* border-radius: 10%;
     color: red;
    border: 1px red solid; */
  }
.btn_submit_common_green{
  border-radius: 4px;
  color: #fff;
  border: 1px solid #16a085 !important;
  background: #16a085 !important;
  line-height: 30px;
}
.btn_submit_common_red{
  border-radius: 4px;
  color: #fff;
  border: 1px solid #cd485c !important;
  background: #cd485c !important;
  line-height: 30px;
}
.modal-dialog {
    min-width: 800px;
    margin: 1.75rem auto;
}

.home-icon{
  font-size: 20px;
  margin-bottom: 7px;
}

.PrivateNotchedOutline-root-2{
  top:0px;
      top: 0px !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    -webkit-transform: translate(14px, -6px) scale(0.75);
            transform: translate(14px, -6px) scale(0.75);
    background: white;
} 
.MuiOutlinedInput-input {
    padding: 11px 13px !important;
}

.navbar-links{
  /* color:blue !important; */
  color: #fff;
}
.navbar-links:hover{
  /* color:blue !important; */
  color:#16a085 !important;
}
.navbar-active{
  background: white;
  border-radius: 5px;
  padding:10px;
  color: #333;
}

.notification-icon {
  padding-top:5px;
  bottom: 10px;
}

.logout-icon{
  margin-top: 5px;
  cursor: pointer;
}

.left-border-red{
  border-left-width: 3;
  border-left-color: 'red';
  border-left-style:  'solid';
}

/* my css start here */
.activity_chart_main{
  background-color: #fff;
  border-radius: 0 !important;
  width: 100%;
  height: auto; 
  margin-top: 1.5rem;
}
.Opportunites_chart{
  background-color: #fff;
  border-radius: 0 !important;
  width: 100%;
  height: auto; 

}
.revenue_maindiv{
  background-color: #fff;
  border-radius: 10px !important;
  width: 100%;
  height: 31.5rem; 
}
.title_main{
  color: #4E61C4;
  font-family: "Poppins SemiBold";
  font-size: 30px;
  line-height: 28px;
  font-weight: bold;
  padding-top: 3rem;
  padding-bottom: 1rem;
}


 /* navbar css start here */
 .btn_add_common{
  background: #16a085 !important;
  border: 1px solid #16a085 !important;
  margin-left: 1rem;
  color: #fff;
  outline: none !important;
  font-size: 1rem !important;
}
.btn_add_common11{
  background: #cd485c !important;
  border: 1px solid #cd485c !important;
  margin-left: 1rem;
  color: #fff;
  outline: none !important;
  font-size: 1rem !important;
}
.btn_add_common:focus, .btn_add_common:active{
  outline: none !important;
  border: 1px solid #16a085 !important;
  box-shadow:none !important;
}

/* navbar new css added here */
@media (min-width: 992px){
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
  }
}

@media screen and (max-width:991px){
.nav-item{
  display: flex;
    justify-content: center;
    align-items: center;
}
.headerBand {
  background: #cd485c !important;
  color: #FFFFFF;
  width: 100% !important;
  border-radius: 50px !important;
  line-height: 30px !important;
  margin-right: 0rem !important;
}
}

@media screen and (max-width:800px) {
  .modal-dialog {
      min-width: 100%;
      margin: 1.75rem auto;
  }
}

/* home page css start here */
.main_dashboard_page{
  background-color: #C8C8C8;
  width: 100%;
  /* height:90vh; */
  padding: 8rem 0;
}
.total_tickets{
  width: 100%;
  height:10rem;
  background: #16a085;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
justify-content: center;

}
.total_tickets1{
  width: 100%;
  height:10rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
justify-content: center;
  background: #cd485c;

}
.ticket_icon{
  color: #fff;
  font-size: 2.5rem;
}
.total_tickets h4, .total_tickets1 h4{ 
color: #fff;
padding-top: 1rem;
font-size: 1.2rem;
}
.total_tickets h6, .total_tickets1 h6{ 
  color: #fff;
  font-size: 2.2rem;
  }


  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: 'Attachment';
    display: inline-block;
    background:#16a085;
    border: 1px solid #16a085;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;

    -webkit-user-select: none;
    cursor: pointer;
    /* text-shadow: 1px 1px #fff; */
    /* font-weight: 700; */
    font-size: 15px;
    color: #fff;
  }
  .custom-file-input:hover::before {
    border-color: #16a085;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #16a085, #16a085);
  }



.form_bg_main{
    background: #fff;
    border-radius: 10px;
    margin-top: 5rem;
    padding: 3rem !important;
}

/* .main_feedbackpage{
    background-color: #C8C8C8;
} */
.modal-image{
    max-height: 210px !important;
    width: 15rem;
  }
.forgotBox{
    height: 250px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.loginWrapper12 {
    width: 40%;
    height: 70%;
    display: flex;
    grid-gap:"20px";
    gap:"20px"
  }
.ResetBox{
    height: 500px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.main_profile_layout{
    overflow: hidden;
}
.profile {
    width: 100vw;
    height: 100vh;
    background-color: #f0f2f5;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .loginWrapper_profile {
    width: 40%;
    height: 70%;
    display: flex;
    grid-gap:"20px";
    gap:"20px";
    /* background: #fff; */
    /* border-radius: 16px; */

  }
  /* .loginWrapper {
    width: 50%;
    height: 70%;
    display: flex;
    gap:"20px";
    
  } */
  .registerBox{
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .registerBox_profile{
    height: 670px !important;
  }
  .profile{
      display: flex;
  }
  
  .profileRight{
      flex: 9 1;
  }
  
  .profileCover{
      height: 320px;
      position: relative;
  }
  
  .profileCoverImg{
      width: 100%;
      height: 250px;
      object-fit: cover;
  
  }
  
  .profileUserImg{
      width: 125px;
      height: 125px;
      border-radius: 50%;
      object-fit: cover;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: 100px;
      border: 3px solid white;
      cursor: pointer;
   
  }
  
  .profileInfo{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }
  
  .profileInfoName{
      font-size: 24px;
  }
  
  .profileInfoDesc{
      font-weight: 300;
  }
  
  .profileRightBottom{
      display: flex;
  }
  
  #input[type="file"] {
      display: none;
  }
  .label{
    width: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }
  .image-upload{
    margin: auto;
    width: 200px;
    height: 30px;
    color: white;
    border-radius: 10px;
    background-color: black;
    text-align: center;
    cursor: pointer;
  }
.login {
  width: 100vw;
  height: 100vh;
  background-color: #f0f2f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginWrapper {
  width: 50%;
  height: 70%;
  display: flex;
  grid-gap:"20px";
  gap:"20px";
  
}

.loginLeft,
.loginRight {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem;
}

.loginLogo {
  font-size: 50px;
  font-weight: 800;
  font-weight: bold;
  color: #1775ee;
  margin-bottom: 10px;
}

.loginDesc {
  font-size: 24px;
}

.loginBox{
    height: 300px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.loginInput{
    height: 50px;
    border-radius: 10px;
    border: 1px solid gray;
    font-size: 18px;
    padding-left: 20px;
}

.loginInput:focus{
    outline: none;
}

.loginButton{
    height: 50px;
    border-radius: 10px;
    border: none;
    background-color: #294086;
    color: white;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

.loginButton:focus{
  outline: none;
}

.loginButton:disabled{
  cursor: not-allowed;
}

.loginForgot{
    text-align: right;
    color: #1775ee;
    float:right;
}

.loginRegisterButton{
    width: 60%;
    align-self: center;
    height: 50px;
    border-radius: 10px;
    border: none;
    background-color: #42b72a;
    color: white;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}


