.form_bg_main{
    background: #fff;
    border-radius: 10px;
    margin-top: 5rem;
    padding: 3rem !important;
}

/* .main_feedbackpage{
    background-color: #C8C8C8;
} */
.modal-image{
    max-height: 210px !important;
    width: 15rem;
  }